import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

import Seo from "@/components/Seo"
import loadable from "@loadable/component"

import { languageCodes } from "@/utils/userresearch/languages"


const Layout = loadable(() => import("@/components/UserResearch/Layout"))
const Banner = loadable(() => import("@/components/UserResearch/HomePage/Banner"))
const SignUp = loadable(() => import("@/components/UserResearch/HomePage/SignUp"))
const Contribute = loadable(() =>
  import("@/components/UserResearch/HomePage/Contribute")
)
const Steps = loadable(() => import("@/components/UserResearch/HomePage/Steps"))
const Research = loadable(() => import("@/components/UserResearch/HomePage/Research"))
const Rewards = loadable(() => import("@/components/UserResearch/HomePage/Rewards"))
const CallToAction = loadable(() => import("@/components/UserResearch/HomePage/CallToAction"))

const UserResearchHomePage = () => {
  const { language, changeLanguage, originalPath, defaultLanguage } =
    useI18next()

  useEffect(() => {
    if (!languageCodes.includes(language)) {
      changeLanguage(defaultLanguage, originalPath, { replace: true })
    }
  }, [language, changeLanguage, originalPath, defaultLanguage])

  return (
    <Layout>
      <Seo title="User Research" />
      <Banner />
      <SignUp />
      <Contribute />
      <Steps />
      <Research />
      <Rewards />
      <CallToAction />
    </Layout>
  )
}

export default UserResearchHomePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["userresearch"] }
        language: { in: [$language, "en"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
